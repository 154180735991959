import styled from 'styled-components';
import { Box, Container } from '@components/layout/Grid';
import { StartOverviewQuery } from '@gql/generated';
import { Title } from '@ecosystems/landing_pages/hero/components';

const Grid = styled.div`
  width: 100%;
  display: grid;
  gap: 30px;
  margin: 20px auto 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(clamp(180px, 30%, 400px), 1fr)
    );
    gap: 20px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    margin-top: 0px;
  }
`;

const Card = styled.div`
  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    transform: scale(1);
    transition: transform 200ms ease-in-out;

    :hover {
      transform: scale(1.01);
    }
  }
`;

const CardImage = styled.img`
  width: 100%;
  border-radius: 4px;
  background-color: #cecece;
  margin-right: 10px;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    width: 100%;
    aspect-ratio: 1.5;
    margin-right: 0;
    margin-bottom: 1em;
  }
`;

const CardDesc = styled.p`
  color: #242424;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
`;

const Feature = ({ className = '', title, desc, image }) => {
  return (
    <Card className={className}>
      <CardImage src={image} />
      <Box>
        <Title className="flex items-center !mb-[0.6em]" as="h3">
          {title}
        </Title>
        <CardDesc>{desc}</CardDesc>
      </Box>
    </Card>
  );
};

const Anchor = styled.a`
  text-decoration: none;
`;

type Props = {
  data: StartOverviewQuery['startOverview']['otherFeaturesSection'];
};

export const OtherYogobeFeaturesSection = (props: Props) => {
  const {
    data: { title, otherFeatures },
  } = props;

  return (
    <Container
      flexDirection="column"
      alignItems="center"
      px={[20, 20, 20]}
      my={[20, 20, 40]}
    >
      <Title as="h2" className="text-center">
        {title}
      </Title>
      <Grid>
        {otherFeatures.map((item, i) => {
          return (
            <Anchor key={i} href={item.url}>
              <Feature
                title={item.title}
                desc={item.description}
                image={item.imageUrl}
              />
            </Anchor>
          );
        })}
      </Grid>
    </Container>
  );
};
