import styled from 'styled-components';
import { Container } from '@components/layout/Grid';
import Hero from '@ecosystems/landing_pages/hero/Hero';
import {
  CategoryTitle,
  VideoWrapper,
  TextWrapper,
  TextWrapperInner,
  Title,
  ActionsWrapper,
} from '@ecosystems/landing_pages/hero/components';
import { StartOverviewQuery } from '@gql/generated';
import { Button } from '@components/ButtonV2';

const Section = styled(Hero)<{ reverseColumns: boolean }>`
  width: 100%;
  flex-direction: column;

  ${VideoWrapper} {
    img {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  ${TextWrapper} {
    flex: 1;

    ${TextWrapperInner} {
      align-items: center;
      text-align: center;
      padding-top: 20px;
    }

    ${CategoryTitle} {
      font-size: 10px;
      color: white;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 3px;
      padding: 3px 5px;
      background-color: #6a7c8f;
    }

    ${Title} {
      margin-bottom: 0.5em;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    flex-direction: ${({ reverseColumns }) =>
      reverseColumns ? 'row-reverse' : 'row'};

    ${TextWrapper} {
      padding: 60px 0 60px 60px;

      ${TextWrapperInner} {
        align-items: flex-start;
        text-align: unset;
      }
    }
  }
`;

type Props = {
  data: StartOverviewQuery['startOverview']['campaignSection'];
  reversed?: boolean;
};

export const CampaignSection = (props: Props) => {
  const { title, description, buttonLink, buttonText, imageUrl, tag } =
    props.data || {};

  if (!(title && description)) {
    return null;
  }

  return (
    <Container px={20} py={[25, 30, 40]}>
      <Section
        titleAs="h2"
        title={title}
        description={description}
        category={tag}
        image={imageUrl}
        actions={
          buttonLink
            ? [
                <Button key="0" href={buttonLink} appearance="blue" rounded>
                  {buttonText}
                </Button>,
              ]
            : []
        }
        reverseColumns={props.reversed}
      />
    </Container>
  );
};
