import styled from 'styled-components';
import { Container } from '@components/layout/Grid';
import hero from '@ecosystems/landing_pages/hero/Hero';
import { Button as button } from '@components/ButtonV2';
import {
  VideoWrapper,
  Title as HeroTitle,
  Title,
} from '@ecosystems/landing_pages/hero/components';
import Icon from '@components/Icon';
import { StartOverviewQuery } from '@gql/generated';

// const Title = styled.h2`
//   max-width: 620px;
//   color: #243240;
//   font-size: 22px;
//   font-weight: bold;
//   line-height: 34px;
//   text-align: center;
//   margin: 0 0 20px;

//   @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
//     font-size: 28px;
//     margin-bottom: 40px;
//   }
// `;

const SubTitle = styled.p`
  max-width: 620px;
  color: #243240;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    font-size: 18px;
  }

  &:empty {
    display: none;
  }
`;

const Hero = styled(hero)`
  padding: 20px 0;

  :first-of-type {
    padding-top: 0;
  }
  :last-of-type {
    padding-bottom: 0;
  }

  ${HeroTitle} {
    margin-bottom: 0;
  }

  ${VideoWrapper} {
    img {
      max-height: 460px;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    ${HeroTitle} {
      margin-bottom: 20px;
    }
  }
`;

const Button = styled(button)`
  display: flex;
  align-items: center;

  i {
    display: inline-block;
    transform: rotate(180deg);
    margin-left: 6px;
  }
`;

type Props = {
  data: StartOverviewQuery['startOverview']['coursesSection'];
};

export const CoursesSection = (props: Props) => {
  const {
    data: {
      title,
      description,
      group1Title,
      group1Description,
      group1ButtonLink,
      group1ButtonText,
      group1ImageUrl,
      group2Title,
      group2Description,
      group2ButtonLink,
      group2ButtonText,
      group2ImageUrl,
    },
  } = props;
  return (
    <Container
      flexDirection="column"
      alignItems="center"
      px={[20, 20, 20]}
      my={[20, 20, 40]}
    >
      <Title as="h2" className="text-center pb-4 md:pb-4 lg:pb-0">
        {title}
      </Title>
      <SubTitle>{description}</SubTitle>
      <Hero
        image={group1ImageUrl}
        title={group1Title}
        titleAs="h3"
        description={group1Description}
        actions={
          group1ButtonLink
            ? [
                <Button key="0" href={group1ButtonLink} appearance="blue" link>
                  {group1ButtonText}{' '}
                  <Icon name="ygb-icon-Back-arrow-ic" fontSize="18px" />
                </Button>,
              ]
            : []
        }
      />
      {group2Title && group2Description ? (
        <Hero
          image={group2ImageUrl}
          title={group2Title}
          titleAs="h3"
          description={group2Description}
          actions={
            group2ButtonLink
              ? [
                  <Button
                    key="0"
                    href={group2ButtonLink}
                    appearance="blue"
                    link
                  >
                    {group2ButtonText}{' '}
                    <Icon name="ygb-icon-Back-arrow-ic" fontSize="18px" />
                  </Button>,
                ]
              : []
          }
          reverseColumns
        />
      ) : null}
    </Container>
  );
};
