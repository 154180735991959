import { useContext } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { Container } from '@components/layout/Grid';
import hero from '@ecosystems/landing_pages/hero/Hero';
import { Button as button } from '@components/ButtonV2';
import {
  VideoWrapper,
  Title,
  TextWrapperInner,
} from '@ecosystems/landing_pages/hero/components';
import LanguageContext from '@lib/contexts/languageContext';
import { UserContext } from '@lib/contexts/UserProvider';
import { FeaturesSection as FeaturesSection_T } from '@gql/generated';

const Hero = styled(hero)`
  width: 100%;
  margin: 0;

  ${Title} {
    line-height: 1.4em;
  }

  ${VideoWrapper} {
    img {
      max-height: 460px;
      width: 100%;
      overflow: hidden;
    }
  }

  ${TextWrapperInner} {
    text-align: center;
    align-items: center;
    padding-top: 20px;
  }

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    ${TextWrapperInner} {
      align-items: flex-start;
      text-align: unset;
    }
  }
`;

const Features = styled.ul`
  list-style: none;
  padding-left: 4px;

  li {
    display: flex;
    align-items: center;
    color: #242424;
    font-size: 16px;
    text-align: left;
    line-height: 1.4;
    margin-bottom: 18px;

    :before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50px;
      margin-right: 14px;
      background-color: #aaa;
    }
  }
`;

const Button = styled(button)`
  margin-right: 10px;
`;

type Props = {
  className?: string;
  data: FeaturesSection_T;
  authenticated?: boolean;
  reverseColumns?: boolean;
};

export const FeaturesSection = (props: Props) => {
  const lang = useContext(LanguageContext);
  const [currentUser, loading] = useContext(UserContext);
  const { authenticated, data, reverseColumns = true } = props;
  const { title, imageUrl, featureBullets } = data || {};

  const actions = [];
  if (!authenticated || (!currentUser && !loading)) {
    actions.push(
      <Button
        key="0"
        appearance="blue"
        href={`/${lang}/register/account`}
        rounded
      >
        <Trans id="homepage.sections.register_btn" />
      </Button>
    );
  }

  return (
    <Container
      flexDirection="column"
      alignItems="center"
      px={[20, 20, 20]}
      my={40}
    >
      <Hero
        titleAs="h2"
        image={imageUrl}
        title={title}
        description={
          <Features>
            {featureBullets?.map?.((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </Features>
        }
        actions={actions}
        reverseColumns={reverseColumns}
      />
    </Container>
  );
};
